import React from 'react'
import { MeetingContactPage } from '../../../components/MeetingContactPage'

import { OSS_REQUEST } from '../../../../content/data/contactPages'

const OpenSourceSupportRequest = () => {

    return (
        <>
            <MeetingContactPage pageData={OSS_REQUEST} />
        </>
    )
}

export default OpenSourceSupportRequest
